<template>

<div class="service" :class="{'is-active': selected}" v-tooltip="'Click to toggle service'" v-on:click="$emit('click')">

	<div class="service-name">{{ item.name }}</div>

	<div class="service-body">

		<div class="service-icon" v-if="!selected"><app-icon :icon="item.icon" /></div>
		<div class="service-icon" v-if="selected"><app-icon icon="tick" /></div>

		<div class="service-text">{{ item.text }}</div>

	</div>

	<div class="service-fees">

		<div>

			<span :class="{'is-saving': canSave}">{{ item.fee | formatMoney }}</span>
			<template v-if="canSave">{{ item.save | formatMoney }}</template>
		</div>

		<div class="service-fees-first">50% off first convention - {{ half(item.fee) | formatMoney }}</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['item', 'selected', 'level', 'count'],

	computed: {

		canSave: function() {

			return (this.count === 3 || (this.count === 2 && !this.selected)) && this.item.save !== false

		}

	},

	methods: {

		half: function(fee) {

			return fee / 2

		}

	}

}

</script>

<style scoped>

.service {
	height: 100%;
	display: flex;
	flex-direction: column;
	transition: transform 100ms linear;
	cursor: pointer;
}

.is-desktop .service:hover {
	transform: scale(1.05);
}

.service-name {
	background-color: #1e3054;
	color: #fff;
	text-align: center;
	padding: 20px;
	font-weight: 400;
	font-size: 20px;
	border-radius: 10px 10px 0px 0px;
	flex-shrink: 0;
}

.service.is-active .service-name {
	background-color: #ffbe7a;
	color: #fff;
}

.service-body {
	border: 1px solid #1e3054;
	padding: 20px;
	display: flex;
	background-color: #fff;
	flex-direction: column;
	align-items: center;
	border-top: 0px;
	flex-grow: 1;
	border-bottom: 0px;
}

.service.is-active .service-body {
	border-color: #ffbe7a;
}

.service-icon {
	font-size: 96px;
	color: #1e3054;
	line-height: 96px;
}

.service.is-active .service-icon {
	color: #ffbe7a;
}

.service-text {
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	padding-top: 20px;
}

.service-fees {
	border: 1px solid #1e3054;
	background-color: #1e3054;
	color: #fff;
	text-align: center;
	padding: 20px 10px;
	font-size: 20px;
	font-weight: 400;
	border-top: 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	border-radius: 0px 0px 10px 10px;
}

.service.is-active .service-fees {
	border-color: #ffbe7a;
	background-color: #ffbe7a;
}

.service-fees-level-name {
	font-weight: 400;
	color: #333;
	font-size: 14px;
	padding: 10px 0px;
	text-align: center;
	background-color: #1e3054;
	color: #fff;
}

.service.is-active .service-fees-level-name {
	background-color: #ffbe7a;
}

.service-fees-level-fees {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	padding: 10px;
}

.service-fees-level-fees-item {
	font-size: 16px;
	font-weight: 400;
	text-align: center;
	padding: 10px;
	border-radius: 8px;
}

.service-fees-level-fees-item small {
	display: block;
	font-size: 10px;
    opacity: 0.75;
    font-weight: 300;
    display: block;
    margin-top: 4px;
}

.service-fees-level-fees-item.is-active {
	background-color: #1e3054;
	color: #fff;
}

.service.is-active .service-fees-level-fees-item.is-active {
	background-color: #ffbe7a;
	color: #fff;
}

.service.is-active .service-fees-level-fees-item.is-inactive {
	opacity: 0.25;
}

.service.is-active .service-fees-level-fees-item.is-active small {
	opacity: 1;
	font-weight: 400;
}

.service-fees span.is-saving {
	text-decoration: line-through;
	opacity: 0.5;
	margin-right: 5px;
}

.service-fees-first {
	background-color: #ffbe7a;
    color: #333;
    padding: 4px 8px;
    border-radius: 4px;
    display: inline-block;
	font-size: 14px;
	font-weight: 400;
    margin-top: 8px;
}

.service.is-active .service-fees-first {
	color: #fff;
}

</style>